



































































































	import {Component, Vue} from 'vue-property-decorator';
	import {w} from '@/svc/w';
	import {navigationMenu} from "@/router/navigation";

	@Component
	export default class Sidebar extends Vue {
		w = w;

		items = navigationMenu;

		langs = [
			{icon: 'lightbulb_outline', title: 'English'},
			{icon: 'touch_app', title: 'Estonian'},
			{icon: 'chat_bubble', title: 'Russian'},
		]

		items2 = [
			{icon: 'lightbulb_outline', text: 'Notes'},
			{icon: 'touch_app', text: 'Reminders'},
			{divider: true},
			{heading: 'Labels'},
			{icon: 'add', text: 'Create new label'},
			{divider: true},
			{icon: 'archive', text: 'Archive'},
			{icon: 'delete', text: 'Trash'},
			{divider: true},
			{icon: 'settings', text: 'Settings'},
			{icon: 'chat_bubble', text: 'Trash'},
			{icon: 'help', text: 'Help'},
			{icon: 'phonelink', text: 'App downloads'},
			{icon: 'keyboard', text: 'Keyboard shortcuts'}
		]

		get drawer() { return this.w.showDrawer; }
		set drawer(v) { this.w.showDrawer = v; }
	}
