










	import {Component, Prop, Vue} from 'vue-property-decorator';

	@Component({components: {}})
	export default class extends Vue {
		@Prop({default: ''}) wrapClass!: string;
	}
