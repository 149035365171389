
















































	import {Component, Vue} from 'vue-property-decorator';
	import {nav} from "@/router/navigation";
	import MainAction from "@/components/buttons/MainAction.vue";

	@Component({components: {MainAction}})
	export default class extends Vue {
		get linkMore() { return nav.about.intro }
	}
