












































	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import Card from "@/components/AckCard.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";

	@Component({components: {SectionTitle, Card, GmSection}})
	export default class extends Vue {
		get sectionId() {return HashLink.lics}
		tab = null;

		isos = [
			{img: "iso/9001 ENG.jpg", pdf: "iso/9001 ENG.pdf", title: "ISO 9001:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img: "iso/14001 ENG.jpg", pdf: "iso/14001 ENG.pdf", title: "ISO 14001:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img: "iso/AQAP 2110 ENG.jpg", pdf: "iso/AQAP 2110 ENG.pdf", title: "AQAP 2110:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
		]

		lics = [
			{title: 'Hazardous Waste Handling License', desc: '', ico: 'file-word', pdf: 'lics/0380m4_GreenMarine_ohtlike_jäätmete-käitluslitsents.pdf'}
			, {title: 'Environmental Complex License', desc: '', ico: 'passport', pdf: 'lics/Keskkonnakompleksluba.pdf'}
			, {title: 'Waste Permit', desc: 'Harjumaa', ico: 'file', pdf: 'lics/UUS_Harjumaa alates 06.02.2018 (kehtiv kuni 18.08.21).pdf'}
			, {title: 'Waste Permit', desc: 'Hiiumaa', ico: 'file', pdf: 'lics/UUS_Hiiumaa alates 22.02.2018 (kuni 21.02.2023).pdf'}
			, {title: 'Waste Permit', desc: 'Ida-Virumaa', ico: 'file', pdf: 'lics/UUS_Ida-Virumaa alates 13.03.18 (kehtiv kuni 17.09.19).pdf'}
			, {title: 'Waste Permit', desc: 'Lääne-Virumaa', ico: 'file', pdf: 'lics/UUS_LääneVirumaa alates 09.04.2018 (kehtiv kuni 01.03.2020).pdf'}
		]

		acks = [
			{img: "images/ack/page_1_thumb_large.jpg", title: "European Sea Ports Organisation Award", desc: "GreenMarine has been nominated to be a member of ESPO"},
			{img: "images/ack/Laanemere-Sober-2014.jpg", title: "Friend of Baltic Sea", desc: '"Friend of Baltic Sea" Award'},
			{img: "images/ack/Aasta-keskkonnasobralik-ettevote-2013.jpg", title: "Environmentally Friendly Company", desc: "The nomination of Ministry of the Environment"},
			{img: "images/ack/GM-EEET2018_digi-1.jpg", title: "The Strongest in Estonia", pdf: 'pdf/bisnode/AAA 2018 (ENG).pdf', desc: "The nomination of International Credit Info."},
			{img: "images/ack/aaa.jpg", title: "Bisnode Rating Classification AAA®.", pdf: 'pdf/bisnode/AAA 2018 (ENG).pdf', desc: "Belongs to the highest class in the Rating Classification AAA®."},
			{img: "images/ack/Annual Report Achievements.jpg", title: "Clean Sea | Project AWARE 2019", desc: 'Every year we participate in international "Clean Sea" maneuvers.'},

			{img: "images/ack/Paldiski-Talgupaev-2013.jpg", title: "Baldiski Cleanup Day", desc: '...'},
			{img: "images/ack/Hageri-Hooldekodu-Tanukiri.jpg", title: "Hageri-Hooldekodu", desc: '...'},
			{img: "images/ack/Viljandi-Linn-Tanukiri.jpg", title: "Viljandi Acknowledgement", desc: '...'},
			{img: "images/ack/Sukeldujate-Klubi-2013.jpg", title: "Diving Club", desc: '...'},
		]
	}
