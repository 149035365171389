





















































	import {Component, Vue} from 'vue-property-decorator';

	@Component
	export default class GmFooter extends Vue {
		fa(ico){return `fas fa-${ico} fa-fw mr-2 footer-ico`}
	}
