












	import {Component, Vue} from 'vue-property-decorator';
	import Carousel from "@/core/carousel/Carousel.vue";
	import Mission from "@/views/about/Mission.vue";
	import Recognition from "@/views/about/Recognition.vue";
	import Lics from "@/views/about/Lics.vue";
	import Intro from "@/views/about/Intro.vue";
	import Env from "@/views/about/Env.vue";
	import Clients from "@/views/about/Clients.vue";

	@Component({components: {Carousel, Mission, Recognition, Lics, Intro, Clients, Env}})
	export default class About extends Vue {
	}
