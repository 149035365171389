




















	import {Component, Vue} from 'vue-property-decorator';
	import {nav} from "@/router/navigation";

	@Component
	export default class Slide1 extends Vue {
		get linkMore() { return nav.about.intro }
	}
