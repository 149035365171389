import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify, {VApp} from 'vuetify/lib';
Vue.use(Vuetify);

// Vue.use(Vuetify, {
// 	components: { VApp },
// })

export default new Vuetify({
	icons: {
		iconfont: 'mdi', // default - only for display purposes
	},
});


