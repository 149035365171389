














	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import Card from "@/components/AckCard.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";

	@Component({components: {SectionTitle, Card, GmSection}})
	export default class extends Vue {
		acks = [
			{img: "images/ack/page_1_thumb_large.jpg", title: "European Sea Ports Organisation Award", desc: "GreenMarine has been nominated to be a member of ESPO"},
			{img: "images/ack/Laanemere-Sober-2014.jpg", title: "Friend of Baltic Sea", desc: '"Friend of Baltic Sea" Award'},
			{img: "images/ack/Aasta-keskkonnasobralik-ettevote-2013.jpg", title: "Environmentally Friendly Company", desc: "The nomination of Ministry of the Environment"},
			{img: "images/ack/GM-EEET2018_digi-1.jpg", title: "The Strongest in Estonia", pdf: 'pdf/bisnode/AAA 2018 (ENG).pdf', desc: "The nomination of International Credit Info."},
			{img: "images/ack/aaa.jpg", title: "Bisnode Rating Classification AAA®.", pdf: 'pdf/bisnode/AAA 2018 (ENG).pdf', desc: "Belongs to the highest class in the Rating Classification AAA®."},
			{img: "images/ack/Annual Report Achievements.jpg", title: "Clean Sea | Project AWARE 2019", desc: 'Every year we participate in international "Clean Sea" maneuvers.'},

			{img: "images/ack/Paldiski-Talgupaev-2013.jpg", title: "Baldiski Cleanup Day", desc: '...'},
			{img: "images/ack/Hageri-Hooldekodu-Tanukiri.jpg", title: "Hageri-Hooldekodu", desc: '...'},
			{img: "images/ack/Viljandi-Linn-Tanukiri.jpg", title: "Viljandi Acknowledgement", desc: '...'},
			{img: "images/ack/Sukeldujate-Klubi-2013.jpg", title: "Diving Club", desc: '...'},
		]

		get sectionId() {return HashLink.recognition}
	}
