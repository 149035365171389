


































	import {Component, Vue} from 'vue-property-decorator';
	import {w} from '@/svc/w';

	@Component
	export default class MatHeader extends Vue {
		w = w;

		get drawer() {return this.w.showDrawer;}
		set drawer(v) {this.w.showDrawer = v;}

		items = [
			{title:'Click Me'},
			{title:'Click Me'},
			{title:'Click Me'},
			{title:'Click Me 2'},
		];

		get isXs() {
			return this.$vuetify.breakpoint.xsOnly
		}
	}
