
















	import {Component, Vue} from 'vue-property-decorator';
	import {w} from './svc/w';
	import HelloWorld from "@/components/HelloWorld.vue";
	import MatHeader from "@/core/header/MatHeader.vue";
	import GmHeader from "@/core/header/GmHeader.vue";
	import GmFooter from "@/core/footer/GmFooter.vue";
	import Sidebar from "@/core/sidebar/Sidebar.vue";

	@Component({components: {GmFooter, MatHeader, HelloWorld, Sidebar, GmHeader}})
	export default class App extends Vue {
		w = w;

		get ky() { return this.w.title.ky; }
		get kySub() { return this.w.title.kySub; }
		get hc() { return this.w.title.hc; }
		get hcSub() { return this.w.title.hcSub; }
		get isXs() { return this.$vuetify.breakpoint.xsOnly }
		get drawer() { return this.w.showDrawer; }
		set drawer(v) { this.w.showDrawer = v; }

		constructor() {
			super();
		}
		mounted() {
		}
		up() {
			// this.refresh = false;
			// this.$nextTick(()=>{
			// 	this.refresh = true;
			// });
		}
	}
