import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"pink","dark":"","height":"70"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Vuetify Logo","contain":"","src":"https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png","transition":"scale-transition","width":"40"}}),_c(VImg,{staticClass:"shrink mt-1 hidden-sm-and-down",attrs:{"alt":"Vuetify Name","contain":"","min-width":"100","src":"https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png","width":"100"}})],1),_c(VSpacer),_c(VMenu,{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"purple",attrs:{"dark":""}},on),[_vm._v(" test menu ")])]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,on:{"click":function($event){}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)}),1)],1),(_vm.isXs)?_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }