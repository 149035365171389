






































	import {Component, Vue} from 'vue-property-decorator';
	import {navigationMenu} from "@/router/navigation";
	import {w} from '@/svc/w';

	@Component
	export default class GmHeader extends Vue {
		w = w;

		item = null;
		items = navigationMenu;

		get drawer() { return this.w.showDrawer; }
		set drawer(v) { this.w.showDrawer = v; }

		constructor() {
			super();

			this.items[0].active = true;
		}
	}
