




















	import {Component, Vue} from 'vue-property-decorator';
	import Slide1 from "./Slide1.vue";
	import Slide2 from "./Slide2.vue";
	import Slide3 from "./Slide3.vue";
	import Slide4 from "./Slide4.vue";
	import Slide5 from "./Slide5.vue";

	@Component({components: {Slide1, Slide2, Slide3, Slide4, Slide5}})
	export default class extends Vue {
		slides = [0, 1, 2, 3, 4]
	}
