
























































	import {Component, Vue} from 'vue-property-decorator';
	import GetQuote from "@/components/buttons/GetQuote.vue";
	import ServiceItem from "@/components/ServiceItem.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";

	@Component({components: {GmSection, GetQuote, ServiceItem}})
	export default class extends Vue {
		get sectionId() {return HashLink.intro}
	}
