import { render, staticRenderFns } from "./MatHeader.vue?vue&type=template&id=016cb7b5&scoped=true&"
import script from "./MatHeader.vue?vue&type=script&lang=ts&"
export * from "./MatHeader.vue?vue&type=script&lang=ts&"
import style0 from "./MatHeader.vue?vue&type=style&index=0&id=016cb7b5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "016cb7b5",
  null
  
)

export default component.exports