






























































	import {Component, Prop, Vue} from 'vue-property-decorator';

	@Component({components: {}})
	export default class extends Vue {
		dialog = false;

		@Prop() img;
		@Prop() title;
		@Prop() desc;
		@Prop() link;
		@Prop({default:true}) flat;

		get isSm() { return this.$vuetify.breakpoint.smAndDown; }
		get isLg() { return this.$vuetify.breakpoint.lgAndUp; }
		get dWidth() {return this.isSm ? '90%' : this.isLg ? '50%' : '70%';}
	}
