






































	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import Card from "@/components/AckCard.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";

	@Component({components: {SectionTitle, Card, GmSection}})
	export default class extends Vue {
		get sectionId() {return HashLink.env}

		tab = null;

		isos = [
			{img: "iso/9001 ENG.jpg", pdf: "iso/9001 ENG.pdf", title: "ISO 9001:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img: "iso/14001 ENG.jpg", pdf: "iso/14001 ENG.pdf", title: "ISO 14001:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
			{img: "iso/AQAP 2110 ENG.jpg", pdf: "iso/AQAP 2110 ENG.pdf", title: "AQAP 2110:2015", desc: "Hazardous And Non-hazardous Waste Management. Recycling The Waste Containing Petroleum, Oil, Liquid Fuels And Bituminous Minerals."},
		]

		lics = [
			{title: 'Hazardous Waste Handling License', desc: '', ico: 'file-word', pdf: 'lics/0380m4_GreenMarine_ohtlike_jäätmete-käitluslitsents.pdf'}
			, {title: 'Environmental Complex License', desc: '', ico: 'passport', pdf: 'lics/Keskkonnakompleksluba.pdf'}
			, {title: 'Waste Permit', desc: 'Harjumaa', ico: 'file', pdf: 'lics/UUS_Harjumaa alates 06.02.2018 (kehtiv kuni 18.08.21).pdf'}
			, {title: 'Waste Permit', desc: 'Hiiumaa', ico: 'file', pdf: 'lics/UUS_Hiiumaa alates 22.02.2018 (kuni 21.02.2023).pdf'}
			, {title: 'Waste Permit', desc: 'Ida-Virumaa', ico: 'file', pdf: 'lics/UUS_Ida-Virumaa alates 13.03.18 (kehtiv kuni 17.09.19).pdf'}
			, {title: 'Waste Permit', desc: 'Lääne-Virumaa', ico: 'file', pdf: 'lics/UUS_LääneVirumaa alates 09.04.2018 (kehtiv kuni 01.03.2020).pdf'}
		]
	}
