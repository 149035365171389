import axios from 'axios';

class W {
	title = {
		ky:'',
		kySub:'',
		hc:'',
		hcSub:'',
	}

	showDrawer = false;

	doStuff() {
	}
}

export const w = new W();
