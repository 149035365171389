import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"host-sidebar"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/images/slaid_1.jpg","alt":"Responsive image"}}),_c('div',{staticClass:"middle"}),_c(VBtn,{staticClass:"toggle-sidebar",attrs:{"absolute":"","dark":"","small":"","fab":"","top":"","right":"","color":"pink"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c(VIcon,[_vm._v("mdi-close-thick")])],1)],1),_c(VList,_vm._l((_vm.items),function(a,i){return _c(VListGroup,{key:i,attrs:{"prepend-icon":a.icon,"no-action":"","color":"pink"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(a.title))])],1)]},proxy:true}],null,true),model:{value:(a.active),callback:function ($$v) {_vm.$set(a, "active", $$v)},expression:"a.active"}},_vm._l((a.sub),function(b,j){return _c(VListItem,{key:j,attrs:{"link":"","to":b.k}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(b.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(b.title))])],1)],1)}),1)}),1),_c(VDivider),_c(VList,[_c(VMenu,{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,_vm._g({},on),[_c(VListItemAction,[_c(VIcon,[_vm._v("mdi-language-haskell")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Keel")])],1)],1)]}}])},[_c(VCard,{attrs:{"tile":""}},[_c(VList,[_c(VSubheader,[_vm._v("KEELED")]),_vm._l((_vm.langs),function(b,i){return _c(VListItem,{key:i,attrs:{"link":"","to":b.k}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(b.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(b.title)}})],1)],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }