























	import {Component, Vue} from 'vue-property-decorator';
	import {nav} from "@/router/navigation";
	import GetQuote from "@/components/buttons/GetQuote.vue";

	@Component({components: {GetQuote}})
	export default class extends Vue {
		get linkMore() { return nav.about.intro }
	}
