










































	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";

	@Component({components: {SectionTitle, GmSection}})
	export default class extends Vue {
		get sectionId() {return HashLink.mission}
	}
