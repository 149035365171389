import Vue from 'vue'
import VueRouter from 'vue-router'
import About from "@/views/About.vue";
import {nav} from "@/router/navigation";

Vue.use(VueRouter)

const routes = [
	{path: '/', name: 'Home', component: About},

	{path: nav.about.top, name: 'About', component: About},
	{path: nav.services.top, name: 'Services', component: () => import('@/views/Services.vue')},
	{path: nav.mts.top, name: 'Mts', component: () => import('@/views/Services.vue')},
	{path: nav.gallery.top, name: 'Gallery', component: () => import('@/views/Gallery.vue')},
	{path: nav.contact.top, name: 'Contacts', component: () => import('@/views/Contacts.vue')},

	{path: '/demo', name: 'Demo', component: () => import('@/views/Demo.vue')},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,

	scrollBehavior(to, from, savedPosition): any {
		if (to.hash) {
			return {
				selector: to.hash
			}
		}
		return {offset: {x: 0, y: 0}}
	}
});

export default router
