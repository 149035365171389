import {IMenuDesc} from "@/common/defs";
import {blueGrey, deepPurple, grey, orange} from "@/common/colors";

function fa(icon) {
	return `fa fa-${icon} fa-fw`
}

export const sideIconStyle = {
	color: blueGrey[700]
}
export const topIconStyle = {
	color: grey[600]
}
export const icoStyleSide = (m: IMenuDesc) => m.col ? {color: m.col} : sideIconStyle;
export const icoStyleTop = (m: IMenuDesc) => m.col ? {color: m.col} : topIconStyle;

const about = '/about';
const services = '/services';
const mts = '/mts';
const gallery = '/gallery';
const contacts = '/contacts';

export enum HashLink {
	intro = 'intro',
	mission = 'mission',
	recognition = 'recognition',
	lics = 'lics',
	clients = 'clients',
	env = 'env',

	srvOverview = 'overview',
	srvWasteMan = 'waste-management',

	conMap = 'map',
	conLocation = 'addr',
	conLogistics = 'logistics',
}

export const nav = {
	about:{
		top: about,
		intro: `${about}#${HashLink.intro}`,
		mission: `${about}#${HashLink.mission}`,
		recognition: `${about}#${HashLink.recognition}`,
		lics: `${about}#${HashLink.lics}`,
		clients: `${about}#${HashLink.clients}`,
		env: `${about}#${HashLink.env}`,
	},
	// Olme-, bio-, taaskasutatavate-, ohtlike tahkete jäätmete käitlemine
	// Ehitus- ja lammutusjäätmete käitlemine
	// Ohtlike vedelate jäätmete käitlemine
	// Kogumisvahendite rent
	// Veovahendite rent
	// Kahveltõstuki rent
	// Kogumisvahendite hooldus ja pesu
	services:{
		top: services,
		overview: `${services}#${HashLink.srvOverview}`,
		wasteManagement: `${services}#${HashLink.srvWasteMan}`,
		constructionWaste: `${services}#construction-waste`,
		hazardousWaste: `${services}#hazardous-waste`,
		containerHire: `${services}#container-rent`,
		equipmentHire: `${services}#equipment-hire`,
		equipmetService: `${services}#equipment-servicing`,
	},
	mts: {
		top: `${mts}`,
		presentation: `${mts}#presentation`,
		handling: `${mts}#handling`,
	},
	gallery:{
		top: `${gallery}`,
		machinery: `${gallery}/machinery`,
		containers: `${gallery}/containers`,
		ships: `${gallery}/ships`,
		team: `${gallery}/team`,
		waste: `${gallery}/waste`,
	},
	contact:{
		top: `${contacts}`,
		map: `${contacts}#${HashLink.conMap}`,
		addr: `${contacts}#${HashLink.conLocation}`,
		logistics: `${contacts}#${HashLink.conLogistics}`,
	}
}

export const navigationMenu: IMenuDesc[] = [
	{k: nav.about.top, title:'Ettevõttest', subheader:'TUTVUSTUS', icon: 'mdi-information', col: orange[900], sub: [
		{k: nav.about.intro, title: 'Ettevõtte lühitutvustus', icon: 'mdi-message-plus-outline'},
		{k: nav.about.mission, title: 'Missioon ja visioon', icon: 'mdi-charity'},
		{k: nav.about.recognition, title: 'Acknowledgements', icon: 'mdi-shield-sun-outline'},
		// {k:'vision', title: 'Vision', icon:fa('recycle')},
		// {k:'lics', title: 'Permits and Licenses', icon:fa('play-circle')},
		{k: nav.about.lics, title: 'Load, litsensid ja sertifikaadid', icon: 'mdi-certificate'},
		{k: nav.about.clients, title: 'Kliendid ja partnerid', icon: 'mdi-account-tie'},
		{k: nav.about.env, title: 'Keskkonnapolitika', icon: 'mdi-language-swift'},
		// {k:'cert', title: 'ISO Certificate', icon:fa('certificate')},
	]},
	{k: nav.services.top, title: 'Teenused', subheader:'TEENUSTE ÜLEVAADE', icon: 'mdi-wrench-outline', sub: [
		{k: nav.services.overview, title: 'Valdkonnad', icon: "mdi-format-list-checkbox" },
		{k: nav.services.wasteManagement, title: 'Jäätmete käitlemine', icon: "mdi-recycle" },
		{k: nav.services.constructionWaste, title: 'Ehitus jäätmed', icon: "mdi-warehouse" },
		{k: nav.services.hazardousWaste, title: 'Ohtlikud jäätmed', icon: "mdi-biohazard" },
		{k: nav.services.equipmentHire, title: 'Vahendite rent', icon: "mdi-forklift" },
		{k: nav.services.equipmetService, title: 'Kogumisvahendite hooldus', icon: "mdi-tools" },
		{k: nav.mts.top, title: 'MTK', icon: "mdi-ev-station", col: deepPurple[400], sub: [
				// {k:'mts', title:'MTS', icon:"fa fa-industry",sub:[
				// {k: 'mts-overview', title: 'MTS overview', icon: fa('copyright')},
				// {k: 'mts-presentation', title: 'Presentation', icon: fa('poll')},
				// {k: 'mts-handling', title: 'Handling scheme', icon: fa('truck')},
		]},
	]},
	{k: nav.gallery.top, title: 'Galerii', subheader:'PILDID', icon: "mdi-camera-iris", sub: [
		{k: nav.gallery.machinery, title: 'Masinad', icon: 'mdi-tanker-truck'},
		{k: nav.gallery.containers, title: 'Konteinerid', icon: 'mdi-docker'},
		{k: nav.gallery.ships, title: 'Laevad', icon: 'mdi-ferry'},
		{k: nav.gallery.team, title: 'Meeskond', icon: 'mdi-account-cog'},
		{k: nav.gallery.waste, title: 'Jaatmeliigid', icon: 'mdi-recycle'},
	]},
	{k: nav.contact.top, title: 'Kontakt', subheader:'VÕTA ÜHENDUST', icon: 'mdi-contacts-outline', sub:[
		{k: nav.contact.addr, title: 'Location', icon: 'mdi-cellphone-android'},
		{k: nav.contact.logistics, title: 'Logistikud', icon: 'mdi-account-clock-outline'},
	]}
]
