import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmSection',{staticClass:"host-env",attrs:{"id":_vm.sectionId,"wrap-class":"host-env-bg"}},[_c(VRow,[_c(VCol,[_c('SectionTitle',{attrs:{"main":"Environmental","sub":"Policy","border-col":"#fff"}})],1)],1),_c(VRow,[_c(VCol,[_c('p',[_vm._v("In April 2010 we successfully passed ISO 14001 certification audit by Bureau Veritas Eesti with scope of certification “Hazardous and non-hazardous waste management”. In 2013 the scope of certification was widened to \"Hazardous and non-hazardous waste management. Recycling the waste containing petroleum, oil, liquid fuels and bituminous minerals\".")])])],1),_c(VRow,{staticClass:"mb-3"},[_c(VCol,[_c(VTabs,{attrs:{"background-color":"transparent","color":"deep-orange darken-4","centered":"","center-active":"","grow":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v("ISO Certificates")]),_c(VTab,[_vm._v("Licenses")]),_c(VTabItem,[_c('div',{staticClass:"card-row mt-4"},_vm._l((_vm.isos),function(a){return _c('Card',{key:a.img,attrs:{"flat":false,"img":a.img,"title":a.title,"desc":a.desc,"link":a.pdf}})}),1)]),_c(VTabItem,[_c('div',{staticClass:"mt-4"},_vm._l((_vm.lics),function(a){return _c(VAlert,{key:a.title,attrs:{"icon":"mdi-file-document","prominent":"","color":"grey lighten-1","dark":""}},[_vm._v(" "+_vm._s(a.title)+" ")])}),1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }