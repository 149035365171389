







	import {Component, Prop, Vue} from 'vue-property-decorator'

	@Component
	export default class SectionTitle extends Vue {
		@Prop({default: 'Main title'}) main!: string;
		@Prop({default: 'Sub title'}) sub!: string;

		@Prop() mainCol!: string;
		@Prop() subCol!: string;
		@Prop() borderCol!: string;

		get borderStyle() {
			return this.borderCol ? {'border-bottom-color': this.borderCol} : {};
		}
	}
