import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":_vm.dWidth,"scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VCard,_vm._g({staticClass:"host-ack-card",attrs:{"flat":_vm.flat,"outlined":!_vm.flat}},on),[_c(VImg,{staticClass:"media align-start",attrs:{"height":"140px","src":_vm.img,"position":"center top"}}),_c(VCardTitle,[_c('h5',[_vm._v(_vm._s(_vm.title))])]),_c(VCardSubtitle,[_c('p',[_vm._v(_vm._s(_vm.desc))])]),_c(VCardActions)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{attrs:{"primary-title":""}},[_c('h5',[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,[_c(VImg,{staticClass:"media align-start",attrs:{"src":_vm.img,"min-height":"20vh","max-height":"80vh","position":"center top","contain":""}})],1),_c(VCardActions,[_c(VSpacer),(_vm.link)?_c(VBtn,{attrs:{"href":_vm.link,"text":"","color":"pink"}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-cloud-download")]),_vm._v(" Download")],1):_vm._e(),_c(VBtn,{attrs:{"color":"pink","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }